import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import styles from "./PenTest.module.css";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { SUCCESS, UPDATE, DELETE, FAILED } from "../../common/MessageConstants";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/Help";
import Input from "../../components/UI/Form/Input/Input";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "../../components/UI/Form/Button/Button";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AutoCompleteDropDown from "../../components/UI/Form/Autocomplete/Autocomplete";
import * as routeConstant from "../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router";
import {
  PENTEST_SCAN_POLICY,
  CREATE_PENTEST_TARGET,
  GET_SCAN_SHEDULE_DATA,
} from "../../graphql/queries/PenTest";
import Cookies from "js-cookie";
import { createTheme, ThemeProvider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  DOMAIN_VERIFY,
  IP_VERIFY,
  URL_VERIFY,
} from "../../graphql/mutations/DomainVerify";
import * as msgConstant from "../../common/MessageConstants";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { InputAdornment } from "@mui/material";
import { Button as ButtonElement } from "@mui/material";
import { DialogBox } from "../../components/UI/DialogBox/DialogBox";
// import { dateTime } from "date-fns/locale/zh-TW/index.js";
import { PG_URL, PG_PRICING_API_KEY, PG_DEDUCTION_API_KEY } from "../../config/index";
import Pagination from "../../components/UI/Pagination/Pagination";
import { SCAN_SCHEDULE } from "../../graphql/mutations/PartnerSchedule";
import { sentry_error_catch } from "../../common/sentry_error_catch";
import * as Sentry from "@sentry/react";
import { useErrorBoundary } from "react-error-boundary";
// const theme = createTheme({
//   overrides: {
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: "rgb(240, 102, 1, 0.8)",
//         borderRadius: "12px",
//         position: "relative",
//         "&:before": {
//           content: "' '",
//           width: "0px",
//           height: "0px",
//           zIndex: 9999,
//           position: "absolute",
//         },
//       },
//       tooltipPlacementRight: {
//         "&:before": {
//           borderTop: "6px solid transparent",
//           borderBottom: "6px solid transparent",
//           borderRight: "6px solid rgba(240, 102, 1, 0.8)",
//           left: "-6px",
//           top: "45%",
//         },
//       },
//       tooltipPlacementLeft: {
//         "&:before": {
//           borderTop: "6px solid transparent",
//           borderBottom: "6px solid transparent",
//           borderLeft: "6px solid rgba(240, 102, 1, 0.8)",
//           right: "-6px",
//           top: "45%",
//         },
//       },
//       tooltipPlacementBottom: {
//         "&:before": {
//           borderLeft: "6px solid transparent",
//           borderRight: "6px solid transparent",
//           borderBottom: "6px solid rgba(240, 102, 1, 0.8)",
//           left: "45%",
//           top: "-6px",
//         },
//       },
//       tooltipPlacementTop: {
//         "&:before": {
//           borderLeft: "6px solid transparent",
//           borderRight: "6px solid transparent",
//           borderTop: "6px solid rgba(240, 102, 1, 0.8)",
//           left: "45%",
//           bottom: "-6px",
//         },
//       },
//     },
//   },
// });

export const PenTest: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  let moment = require("moment-timezone");
  const history = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState<any>(location.state[Object.keys(location.state)[0]]);
  let { name, partnerId, clientId } = param?.clientInfo;
  const [priceData, setPriceData] = useState<any>();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [openPricingBox, setOpenPricingBox] = useState<boolean>(false);
  const [showRecurringFields,setShowRecurringFields] = useState<boolean>(false);
  const [dialogReviewMsg,setDialogReviewMsg] = useState<any>("");
  const [fromWhereLocalIpAdded,setFromWhereLocalIpAdded] = useState<any>("");
  const [intervalValue,setIntervalValue] = useState<any>("")
  const [scanNumber,setScanNumber] = useState<any>("")
  const [startDay,setStartDay] = useState<any>("");
  const [month,setMonth] = useState<any>("");
  const [scheduleData,setScheduleData] = useState<any>("");
  const [reviewData,setReviewData] = useState<any>([]);
  const [scheduleCheckBox,setScheduleCheckBox] = useState<any>(false);
  const [showReviewDialogBox,setShowReviewDialogBox] = useState<any>(false);
  const [scheduleError,setScheduleError] = useState<any>({
    interval: false,
    startDay:false,
    month:false,
    scanNumber:false,
    msg:""
  })
  const scanNumberArray = [1,2,3,4,5,6,7,8,9,10,11,12];
  const monthArray = ["January","February","March","April","May","June","July","August","September",
  "October","November","December"];
  const dayArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]

  const partner = Cookies.get("ob_partnerData") || "";
  const pg360PartnerId = JSON.parse(
    localStorage.getItem("pg360PartnerId") || "{}"
  );
  const pg360UserId = JSON.parse(localStorage.getItem("pg360UserId") || "{}");
  let partnerdata = JSON.parse(partner);
  const [url, setUrl] = useState<any>("");
  const [formValues, setFormValues] = useState<any>([{ url: "" }]);
  const [targetName, setTargetName] = useState<String>("");

  const [policyValue, setPolicyValue] = useState<any>("");
  const [policyList, setPolicyList] = useState<any>([]);
  const [reset, setReset] = useState<boolean>(false);
  const [targetOpen, setTargetOpen] = useState(false);
  const [targetCreated, setTargetCreated] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);
  const [urlAddOpen,setUrlAddOpen] = useState<any>(false);
  const startDate = new Date();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [isError, setIsError] = useState<any>({
    url: "",
    policyValue: "",
    targetName: "",
  });
  const clientInfo = param
    ? param.clientInfo
    : undefined;
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    setParam(location.state[Object.keys(location.state)[0]]);
    getPricingData();
    getScanPolicies();
    setSubmitDisabled(checkValidation);
    checkValidation();
  }, []);

  useEffect(() => {
    if (targetCreated === true) {
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: msgConstant.SCAN_CREATED_SUCCESS,
      }));
    }
  }, [targetCreated]);

  const [createPenTestTarget] = useMutation(CREATE_PENTEST_TARGET);
  const [IPVerify] = useMutation(IP_VERIFY);
  const [domainVerify] = useMutation(DOMAIN_VERIFY);
  const [urlVerify] = useMutation(URL_VERIFY);
  const [getScheduleData] = useLazyQuery(GET_SCAN_SHEDULE_DATA,{
    fetchPolicy: "network-only"
  });
  const [createScanSchedule] = useMutation(SCAN_SCHEDULE)

  const [getScanPolicies, { data: spData, loading: spLoading }] = useLazyQuery(
    PENTEST_SCAN_POLICY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        let arr: any = [];
        data.getPentestScanPolicy.edges.map((element: any, index: any) => {
          let obj: any = {};
          obj["policyType"] = element.node.policyType;
          arr.push(obj);
        });
        setPolicyList(arr);
      },
      onError: (err:any) => {
        sentry_error_catch(err,setBackdrop,setFormState)
        history(routeConstant.CLIENT);
      },
    }
  );

  const getPricingData = async () => {
    let headerObj = {
      "api-key": PG_PRICING_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      
      // partner id should be dynamic
      await fetch(PG_URL + "ob360-scans/api/v1/check-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          type: "PT",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setPriceData(data);
        });
    } catch (err:any) {
      Sentry.captureException(err);
    }
  };

  const getAllPolicyValues = {
    options: policyList,
    getOptionLabel: (option: { policyType: String }) => option.policyType,
  };
  const checkValidation = () => {
    if (
      isError.targetName !== "" ||
      isError.url !== "" ||
      isError.policyValue !== "" ||
      !targetName ||
      !url ||
      !policyValue
    ) {
      return true;
    }
    return false;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetName(event.target.value);
    if (!/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      let value = event.target.value;
      let isErrName = value.length <= 0 ? "Required" : "";
      setIsError((isError: any) => ({
        ...isError,
        targetName: isErrName,
      }));
    }
    if (/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      setIsError((isError: any) => ({
        ...isError,
        targetName: "Invalid Scan Name",
      }));
    }
  };

  const confirmSubmit = async () => {
    try{
    setShowReviewDialogBox(false);
    setOpenDialogBox(false);
    let urlArr: any = [];
    let urlErrorFlag = false;
    let scheduleErrorFlag = false;
    let urlErrorMessage = msgConstant.REQUIRED_ALL_FIELD;
    for (let j in formValues) {
      urlArr.push(formValues[j].url);
      if (formValues[j].error) {
        urlErrorFlag = true;
        urlErrorMessage = formValues[j].message;
      }
    }
    let urlString = urlArr.join(",");

    if (urlString.length > 0 && urlErrorFlag === false) {
      if (targetName === "") {
        setIsError((isError: any) => ({
          ...isError,
          targetName: "Required.",
        }));
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_NAME_EMPTY,
        }));
        setBackdrop(false);
      } else {
        if (/[^a-zA-Z0-9\- ]/.test(targetName.toString())) {
          setIsError((isError: any) => ({
            ...isError,
            targetName: "Invalid scan name",
          }));
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: msgConstant.VALID_SCAN_NAME,
          }));
        } else {
          if(scheduleCheckBox){
            if(intervalValue.length === 0 && scanNumber == "" && startDay === ""){
              scheduleErrorFlag = true;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                interval:true,
                msg:msgConstant.SELECT_SCHEDULING_INTERVAL,
              }))
            }else if(intervalValue.length === 0){
              scheduleErrorFlag = true;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                interval:true,
                msg:msgConstant.SELECT_SCHEDULING_INTERVAL,
              }))
            }else if(startDay === ""){
              scheduleErrorFlag = true;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                startDay:true,
                msg:msgConstant.SELECT_SCHEDULING_DAY,
              }))
            }else if(intervalValue === "Quarterly" && month === ""){
              scheduleErrorFlag = true;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                month:true,
                msg:msgConstant.SELECT_SCHEDULING_MONTH,
              }))
            }else if(scanNumber === ""){
              scheduleErrorFlag = true;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                scanNumber:true,
                msg:msgConstant.SELECT_SCHEDULING_SCAN_NUMBER,
              }))
            }else{
              scheduleErrorFlag = false;
              setScheduleError((scheduleError:any) => ({
                ...scheduleError,
                startDay:false,
                interval:false,
                month:false,
                scanNumber:false,
                msg:"",
              }))
            }
          }
          let hostUrl = urlString.replace(/\s+/g, "");
          let scanScheduleData:any = []
          if(scheduleCheckBox === true){
            if(scheduleErrorFlag === false){
              if(scheduleData.length === 0){
                let hostValue = ""
                if(formValues.length > 1){
                  formValues.forEach((val:any,index:any) => {
                    if(index + 1 === formValues.length){
                      hostValue = hostValue + val.url
                    }else{
                      hostValue = hostValue + val.url + ","
                    }
                  })
                }else{
                  hostValue = formValues[0].url
                }
                await getScheduleData({
                  variables:{
                    interval: intervalValue,
                    scanName: targetName,
                    host: hostValue,
                    scanStart: parseInt(startDay),
                    scanType: "Pentest",
                    scanRunDateQuarterlyMonth: month !== "" && intervalValue === "Quarterly"? month : "",
                    noOfScans: parseInt(scanNumber)
                  }
                }).then((data:any) => {
                  if(data.data.getScansheduldata && data.data.getScansheduldata.length > 0){
                    scanScheduleData = data.data.getScansheduldata
                  }
                }).catch((err:any) => {
                  sentry_error_catch(err,setBackdrop,setFormState)
                })
              }else{
                scanScheduleData = scheduleData.map((item:any) => Array.isArray(item) ? [item] : { ...item });
                // scanScheduleData = scheduleData
              }
              const newScanScheduleData = scanScheduleData.map((data:any,index:any) => {
                let obj = {};
                const timestampInMilliseconds = data.scanStart * 1000;
                const date = new Date(timestampInMilliseconds);
                console.log("date",date)
                // date.setUTCHours(0, 0, 0, 0);
                // data["scanStart"] = date
                // return data
                obj = {...data, "scanStart": date};
                console.log("obj",obj);
                return obj;
              })
              console.log("newScanScheduleData",newScanScheduleData);
              newScanScheduleData.forEach((scheduleData:any,index:any) => {
                createScanSchedule({
                  variables:{
                    input: {
                      interval: intervalValue,
                      startdate: scheduleData["scanStart"],
                      partnerId: parseInt(
                        partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                          .id
                      ),
                      clientId: parseInt(param.clientInfo.clientId),
                      userid: parseInt(
                        partnerdata.data.getPartnerUserDetails.edges[0].node.userId.id
                      ),
                      scanName: targetName,
                      host: hostUrl,
                      scanType: "Pentest"
                    }
                  }
                }).then((dataRes:any) => {
                  let responseData = dataRes.data.scanSchedule.targetField 
                  // if(responseData.partnerId && responseData.scanName && responseData.scanType){
                  //   scheduleSuccess = true
                  // }
                  if(index + 1 === newScanScheduleData.length){
                    // if(scheduleSuccess){
                      let formState2 = {
                        isSuccess: true,
                        isUpdate: false,
                        isDelete: false,
                        isFailed: false,
                        errMessage: ` ${responseData.scanName} ${msgConstant.SCHEDULED_SCAN_SUCCESS}.`,
                      };
                      if (Cookies.getJSON("ob_session")) {
                        let data = {};
                        data = {
                          refetchData: true,
                          clientInfo: clientInfo,
                          scheduleScan: newScanScheduleData,
                          formState: formState2,
                        };
                        history(routeConstant.RA_REPORT_LISTING, {state: {data}});
                        localStorage.removeItem("name");
                        localStorage.removeItem("targetId");
                        localStorage.removeItem("ipRange");
                        localStorage.removeItem("ipAddress");
                        localStorage.removeItem("re-runTargetName");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("password");
                        localStorage.removeItem("vpnUserName");
                        localStorage.removeItem("vpnPassword");
                        localStorage.removeItem("vpnFilePath");
                        localStorage.removeItem("WinTargetName");
                        localStorage.removeItem("LinuxTargetName");
                      }
                    // }
                    setShowReviewDialogBox(false)
                  }
                }).catch((err:any) => {
                  setShowReviewDialogBox(false)
                  sentry_error_catch(err,setBackdrop,setFormState)
                })
              })
              clearReviewState()
            }
          }else{
          createPenTestTarget({
            variables: {
              TargetInput: {
                targetName: targetName,
                partner: parseInt(
                  partnerdata.data.getPartnerUserDetails.edges[0].node.partnerId
                    .id
                ),
                startDate: startDate,
                client: parseInt(param.clientInfo.clientId),
                host: hostUrl,
                scanType: "Pentest",
                scanPolicyType: "High Policy",
                user: parseInt(
                  partnerdata.data.getPartnerUserDetails.edges[0].node.userId.id
                ),
              },
            },
          })
            .then((res: any) => {
              setBackdrop(false);
              if (res.data.createPenTest.status === "Duplicate") {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: msgConstant.SCAN_NAME_EXITS_ERROR,
                }));
              } else if (res.data.createPenTest.status === "Success") {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: true,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: false,
                  errMessage: `${res.data.createPenTest.targetField.targetName} ${msgConstant.SCAN_CREATED_SUCCESS}.`,
                }));
                let formState2 = {
                  isSuccess: true,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: false,
                  errMessage: msgConstant.SCAN_SUCCESS_MSG,
                };
                setUrl("");
                setPolicyValue("");
                setTargetName("");
                // handleBack();
                handleDeductScan(res.data.createPenTest.targetField.id);
                if (Cookies.getJSON("ob_session")) {
                  let data = {};
                  data = {
                    refetchData: true,
                    clientInfo: clientInfo,
                    formState: formState2,
                  };
                  history(routeConstant.RA_REPORT_LISTING, {state: {data}});
                  localStorage.removeItem("name");
                  localStorage.removeItem("targetId");
                  localStorage.removeItem("ipRange");
                  localStorage.removeItem("ipAddress");
                  localStorage.removeItem("re-runTargetName");
                  localStorage.removeItem("userName");
                  localStorage.removeItem("password");
                  localStorage.removeItem("vpnUserName");
                  localStorage.removeItem("vpnPassword");
                  localStorage.removeItem("vpnFilePath");
                  localStorage.removeItem("WinTargetName");
                  localStorage.removeItem("LinuxTargetName");
                } else {
                  // logout();
                }
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: msgConstant.API_FAILED_MSG,
                }));
              }
            })
            .catch((err:any) => {
              sentry_error_catch(err,setBackdrop,setFormState,msgConstant.REQUIRED_ALL_FIELD)
            });
          }
        }
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " " + urlErrorMessage,
      }));
    }}catch(error){
      showBoundary(error)
    }
  };

  const handleDeductScan = async (targetId: any) => {
    let headerObj = {
      "api-key": PG_DEDUCTION_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      // partner id should be dynamic
      await fetch(PG_URL + "ob-ra-scans/api/v1/deduct-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          scan_type: "PT",
          main_type: "OB Assessment",
          product_type : "OB",
          uid: pg360UserId,
          timestamp: new Date().getTime() / 1000,
          target_id: targetId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
        });
    } catch (err:any) {
      Sentry.captureException(err);
    }
  };

  const submitAction = () => {
    try{
    if(localUrlValidation(formValues[formValues.length - 1]["url"])
    && !showPopup){
      setShowPopup(true);
    }else{
      if(scheduleCheckBox){
        confirmSubmit()
      }else{
        setOpenDialogBox(true);
      }
    }
    }catch(error){
        showBoundary(error)
    }
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleBack = () => {
    // history(routeConstant.RA_REPORT_LISTING);
    if (Cookies.getJSON("ob_session")) {
      let data = {};
      data = { refetchData: true, clientInfo: clientInfo };
      if (param.previousPage === "client") {
        history(routeConstant.CLIENT, {state: {data}});
        localStorage.removeItem("name");
        localStorage.removeItem("targetId");
        localStorage.removeItem("ipRange");
        localStorage.removeItem("ipAddress");
        localStorage.removeItem("re-runTargetName");
        localStorage.removeItem("userName");
        localStorage.removeItem("password");
        localStorage.removeItem("vpnUserName");
        localStorage.removeItem("vpnPassword");
        localStorage.removeItem("vpnFilePath");
        localStorage.removeItem("WinTargetName");
        localStorage.removeItem("LinuxTargetName");
      } else {
        history(routeConstant.RA_REPORT_LISTING, {state: {data}});
        localStorage.removeItem("name");
        localStorage.removeItem("targetId");
        localStorage.removeItem("ipRange");
        localStorage.removeItem("ipAddress");
        localStorage.removeItem("re-runTargetName");
        localStorage.removeItem("userName");
        localStorage.removeItem("password");
        localStorage.removeItem("vpnUserName");
        localStorage.removeItem("vpnPassword");
        localStorage.removeItem("vpnFilePath");
        localStorage.removeItem("WinTargetName");
        localStorage.removeItem("LinuxTargetName");
      }
    } else {
      // logout();
    }
  };

  const handleTargetToolTipClose = () => {
    setTargetOpen(false);
  };

  const handleTargetToolTipOpen = () => {
    setTargetOpen(true);
  };

  const handleUrlToolTipClose = () => {
    setUrlOpen(false);
  };

  const handleUrlToolTipOpen = () => {
    setUrlOpen(true);
  };

  const handleAddUrlToolTipClose = () => {
    setUrlAddOpen(false);
  };

  const handleAddUrlToolTipOpen = () => {
    setUrlAddOpen(true);
  };

  const urlValidation = (url: string) => {
    var pattern = new RegExp(
      "^(?:\\d{1,3}\\.){3}\\d{1,3}$|^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|HTTP:\\/\\/|https:\\/\\/|HTTPS:\\/\\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
    );

    return pattern.test(url);
  };
  const [showPopup, setShowPopup] = useState(false);

  const localUrlValidation = (url: string) => {
    var pattern = new RegExp('^(192.168.|172.|10.)');
    const isLocalIp = pattern.test(url);
    console.log("isLocalIp--",isLocalIp)
    
    // if (isLocalIp) {
    //   setShowPopup(true);

    //   // setTimeout(() => {
    //   //   setShowPopup(false);
    //   // }, 3000);
    // }

    return isLocalIp;
  };

  const checkLocalIP = () => {
    let validip = localUrlValidation(formValues[formValues.length - 1]["url"]);
    if (validip){
      // console.log("in validip local")
      // let newFormValues = [...formValues];
      // newFormValues[formValues.length - 1]["error"] = true;
      // newFormValues[formValues.length - 1]["message"] = "Entered Local IP Address";
      // setFormValues(newFormValues);
      setShowPopup(true);
    }
  }
  let handleChangeMultiUrl = (i: any, e: any) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    if (e.target.value) {
      let valid = urlValidation(e.target.value);

      if (valid) {
        // console.log("valid--",valid)
        // console.log("e.target.value---",e.target.value)
        // let validip = localUrlValidation(e.target.value);
        // if (validip){
        //   console.log("in validip local")
        //   let newFormValues = [...formValues];
        //   newFormValues[i]["error"] = true;
        //   newFormValues[i]["message"] = "Entered Local IP Address";
        //   setFormValues(newFormValues);
        // }
        let newFormValues = [...formValues];
        newFormValues[i]["error"] = false;
        setFormValues(newFormValues);
      } else {
        let newFormValues = [...formValues];
        newFormValues[i]["error"] = true;
        newFormValues[i]["message"] = msgConstant.ENTER_VALID_URL;
        setFormValues(newFormValues);
      }
    } else {
      let newFormValues = [...formValues];
      newFormValues[i]["error"] = true;
      newFormValues[i]["message"] = msgConstant.EMPTY_URL;
      setFormValues(newFormValues);
    }
  };

  let addFormFields = () => {
    if(localUrlValidation(formValues[formValues.length - 1]["url"]) && !showPopup){
       setShowPopup(true);
       setFromWhereLocalIpAdded("plus");
    }else{
      setFormValues([
        ...formValues,
        { url: "", error: true, message: msgConstant.EMPTY_URL },
      ]);
    }
  };
  console.log("formValues",urlAddOpen);
  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function validateText(str: any) {
    var tarea = str;
    let flag = false;
    if (tarea.contains("http://") || tarea.contains("https://")) {
      flag = false;
    }
    return true;
  }

  const disableAddButton = () => {
    let flag = false;
    for (let i in formValues) {
      if (formValues[i].error === true || !formValues[i].url) {
        flag = true;
        break;
      } else {
        flag = false;
      }
    }
    if (flag === false && formValues.length >= 9) {
      flag = true;
    }
    return flag;
  };
  const handlePricingBoxOpen = () => {
    setOpenPricingBox(true);
  };
  const handlePricingBoxClose = () => {
    setOpenPricingBox(false);
  };
  const cancelLocalIp = () => {
    setFromWhereLocalIpAdded("");
    setShowPopup(false)
    const formVal = [...formValues]
    const obj = formVal.pop()
    const updatedObj = {error: true, message: msgConstant.EMPTY_URL ,url:""}
    formVal.push(updatedObj)
    setFormValues(formVal)
  }
  const intervalChangeHandler = (event:any) => {
    if(event.target.value === "Monthly" && intervalValue === "Quarterly"){
      setMonth("")
    }
    setIntervalValue(event.target.value);
    if(event.target.value === ""){
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        interval:true,
        msg:msgConstant.SELECT_SCHEDULING_INTERVAL,
      }))
    }else{
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        interval:false,
        msg:"",
      }))
    }
  }
  const scanNumberChangeHandler = (event:any) => {
    setScanNumber(event.target.value)
    if(event.target.value === ""){
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        scanNumber:true,
        msg:msgConstant.SELECT_SCHEDULING_SCAN_NUMBER,
      }))
    }else{
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        scanNumber:false,
        msg:"",
      }))
    }
  }
  const handleStartDayChange = (event:any) => {
    setStartDay(event.target.value)
    if(event.target.value === ""){
      setScheduleError((scheduleError:any) => ({
       ...scheduleError,
       startDay:true,
       msg:msgConstant.SELECT_SCHEDULING_DAY,
     }))
    }else{
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        startDay:false,
        msg:"",
      }))
    }
  }
  const handleMonthChange = (event:any) => {
    if (event && event.target.value) {
      setMonth(event.target.value)
    }
    if(event.target.value === ""){
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        month:true,
        msg:msgConstant.SELECT_SCHEDULING_MONTH,
      }))
    }else{
      setScheduleError((scheduleError:any) => ({
        ...scheduleError,
        month:false,
        msg:"",
      }))
    }
  }
  const checkBoxChangeHandler = () =>{
    setShowRecurringFields((showRecurringFields) => !showRecurringFields);
    setScheduleCheckBox((scheduleCheckBox:any) => !scheduleCheckBox);
    if(!scheduleCheckBox === false){
      clearReviewState()
    }
  }
  const clearReviewState = () => {
    setIntervalValue("");
    setStartDay("");
    setMonth("");
    setScanNumber("");
    setScheduleError((scheduleError:any) => ({
      ...scheduleError,
      startDay:false,
      interval:false,
      month:false,
      scanNumber:false,
      msg:"",
    }))
  }
  const closeReviewDialogBox = () => {
    setShowReviewDialogBox(false);
  }
  const reviewScanHandler = async () => {
    try{
    let reviewData:any = [];
    let host = ""
    // if(targetName === "" || (formValues.length === 1 && formValues[0].url === "")||intervalValue.length === 0 || scanNumber == "" || startDay === "" || intervalValue === "Quarterly" && month === ""){
    //   setDialogReviewMsg("No, Schedule Data Found.");
    // }else{
    //query to be executed
    setDialogReviewMsg("");
    if(formValues.length > 1){
      formValues.forEach((val:any,index:any) => {
        if(index + 1 === formValues.length){
          host = host + val.url
        }else{
          host = host + val.url + ", "
        }
      })
    }else{
      host = formValues[0].url
    }
    await getScheduleData({
      variables:{
        interval: intervalValue,
        scanName: targetName,
        host: host,
        scanStart: parseInt(startDay),
        scanType: "Pentest",
        scanRunDateQuarterlyMonth: month !== "" && intervalValue === "Quarterly"? month : "", 
        noOfScans: parseInt(scanNumber)
      }
    }).then((data:any) => {
        if(data.data.getScansheduldata && data.data.getScansheduldata.length > 0){
        data.data.getScansheduldata.forEach((data:any,index:any) => {
          let obj:any = {}
          obj["sr.no"] = index + 1
          obj["interval"] = intervalValue
          obj["scanName"] = data.scanName
          obj["host"] = data.host
          const timestampInMilliseconds = data.scanStart * 1000;
          const date = new Date(timestampInMilliseconds);
          console.log("timeDate",date);
          obj["scheduleDate"] = moment(date).format("MM-DD-YYYY");
          reviewData.push(obj);
        })
        setReviewData(reviewData);
        setScheduleData(data.data.getScansheduldata);
        }
    }).catch((err:any) => {
      sentry_error_catch(err,setBackdrop,setFormState)
    })
    // }
    setShowReviewDialogBox(true);
    }catch(error){
      showBoundary(error);
    }
  }
  const addLocalIpHandler = () => {
    setShowPopup(false);
    if(fromWhereLocalIpAdded === "plus"){
      addFormFields();
    }else{
      submitAction();
    }
    setFromWhereLocalIpAdded("");
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Pen Test for {name}
        {priceData?.scannable === 1 ? (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Credits:{" "}
            {priceData.scan_available.free + priceData.scan_available.credit}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handlePricingBoxOpen}
        >
          <QuestionMarkIcon fontSize="inherit" />
        </IconButton> */}
      </Typography>
      <DialogBox
        open={showReviewDialogBox}
        handleOk={confirmSubmit}
        handleCancel={closeReviewDialogBox}
        buttonOk={"Schedule Scan"}
        buttonCancel={"Cancel"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <Typography component="h1" variant="h1" className={styles.reviewBoxTitle}>
          {"Scan Schedule"}
        </Typography>
        <div className={styles.DialogBoxContext}>
          <table>
            <tr>
              <th>Sr.No</th>
              <th>Interval</th>
              <th>Scan Name</th>
              <th>Host</th>
              <th>Date</th>
            </tr>
            {/* {dialogReviewMsg === "" ? */}
            <tbody>
            {reviewData && reviewData.length > 0 ?
              (reviewData.map((data:any) => {
                  return (
                    <tr>
                      <td>{data["sr.no"]}</td>
                      <td>{data["interval"]}</td>
                      <td>{data["scanName"]}</td>
                      <td>{data["host"]}</td>
                      <td>{data["scheduleDate"]}</td>
                    </tr>
                  )
                })
              ):<td style={{textAlign:"center"}} colSpan={5}>No, Schedule Data Found.</td>
            }
            </tbody>
            {/* :<td style={{textAlign:"center"}} colSpan={5}>{dialogReviewMsg}</td>
            } */}
          </table>
        </div>
      </DialogBox>
      <Grid container spacing={3} className={styles.AlertWrap}>
        {" "}
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              id="success-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              id="success-alert2"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>
        {backdrop ? <SimpleBackdrop /> : null}
        <Grid
          item
          xs={12}
          md={6}
          className={
            param != undefined && param.editData
              ? styles.disfield
              : styles.inputs
          }
        >
          <span className={styles.IPTooltip}>
            <Tooltip
              open={targetOpen}
              onClose={handleTargetToolTipClose}
              onOpen={handleTargetToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b> Scan Name can't contain any special characters. </b>{" "}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="target-name"
                label="Scan Name"
                value={targetName}
                onChange={handleNameChange}
                required
                error={isError.targetName}
                helperText={isError.targetName}
              >
                Scan Name
              </Input>
            </Tooltip>
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          {formValues.map((element: any, index: any) => (
            <Grid className={styles.ipInput} xs={12} container>
              <Grid item xs={8}>
                {index === 0 ? (
                  <span className={styles.IPTooltip}>
                    <Tooltip
                      open={urlOpen}
                      onClose={handleUrlToolTipClose}
                      onOpen={handleUrlToolTipOpen}
                      placement="bottom-end"
                      title={
                        <React.Fragment>
                          <p>
                            <b>You can add upto 9 IP/URL's </b>{" "}
                          </p>
                          <b>{""}</b>
                          <em>
                            {"(e.g. https://192.168.x.xx or http://domain.com)"}
                          </em>
                          <br></br>
                        </React.Fragment>
                      }
                    >
                      <Input
                        type="text"
                        id="url"
                        className={styles.ipInput}
                        name="url"
                        label="URL / IP"
                        value={element.url || ""}
                        onChange={(e: any) => handleChangeMultiUrl(index, e)}
                        required
                        error={element.error ? element.error : false}
                        helperText={element.error ? element.message : false}
                      />
                    </Tooltip>
                  </span>
                ) : (
                  <Input
                    type="text"
                    id="url2"
                    className={styles.ipInput}
                    name="url"
                    label="URL / IP"
                    value={element.url || ""}
                    onChange={(e: any) => handleChangeMultiUrl(index, e)}
                    required
                    error={element.error ? element.error : false}
                    helperText={element.error ? element.message : false}
                  />
                )}
              </Grid>
              <Grid item xs={"auto"} className={styles.IPTooltip}>
                {formValues ? (
                  formValues.length === index + 1 ? (
                    <span className={styles.IPTooltip}>
                      <Tooltip
                        open={urlAddOpen}
                        onClose={handleAddUrlToolTipClose}
                        onOpen={handleAddUrlToolTipOpen}
                        placement="bottom-end"
                        title={
                          <React.Fragment>
                            <p>
                              <b>You can add upto 9 IP/URL's </b>{" "}
                            </p>
                            <b>{""}</b>
                            <em>
                              {
                                "(e.g. https://192.168.x.xx or http://domain.com)"
                              }
                            </em>
                            <br></br>
                          </React.Fragment>
                        }
                      >
                        <Button
                          id="add-fields-btn"
                          className={styles.addButtonStyle}
                          type="button"
                          onClick={() => addFormFields()}
                          color="primary"
                          variant={"contained"}
                          disabled={disableAddButton()}
                        >
                          +
                        </Button>
                      </Tooltip>
                    </span>
                  ) : null
                ) : null}
                {index === 0 ? 
                  <FormControlLabel
                    control={
                      <Checkbox name="Schedule" checked={scheduleCheckBox} color="primary" onChange={checkBoxChangeHandler} />
                    }
                    sx={{ml:0.6,mr:0}}
                    label="Recurring"
                    classes={{root:styles.CheckboxformControl}}
                    labelPlacement="end"
                  />
                :null}
              </Grid>
              <Grid item xs={"auto"}>
                {index ? (
                  <ButtonElement
                    type="button"
                    id="remove-fields-btn"
                    className={styles.removeButtonStyle}
                    onClick={() => removeFormFields(index)}
                    color="primary"
                    variant={"contained"}
                    data-testid="ok-button"
                  >
                    ✕
                  </ButtonElement>
                ) : null}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {showRecurringFields && 
        <Grid item xs={12}>
         <FormControl sx={{ minWidth: 240,mr:2 }} size="small"  error={scheduleError.interval}>
          <InputLabel id="demo-simple-select-label">Interval</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={intervalValue}
            label="Interval"
            defaultValue=""
            onChange={intervalChangeHandler}
            classes={{select:styles.selectInput}}
            sx={{height:43, borderRadius: 2.5}}
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
            {/* <MenuItem value="Yearly">Yearly</MenuItem> */}
          </Select>
          {scheduleError.interval ? <FormHelperText>{scheduleError.msg}</FormHelperText> : null}
         </FormControl>
          <FormControl sx={{ minWidth: 240,mr:2 }} size="small" error = {scheduleError.startDay}>
          <InputLabel id="no-of-select-start-day-label">Select Start Day</InputLabel>
          <Select
            labelId="no-of-select-start-day-label"
            id="demo-simple-select"
            value={startDay}
            label={"Scan Start Day"}
            onChange={handleStartDayChange}
            classes={{select:styles.selectInput}}
            sx={{height:43, borderRadius: 2.5}}
            MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
          >
            {dayArray.map((day:any) => {
              return (
                <MenuItem value={day}>{day}</MenuItem>
              )
            })}
          </Select>
          {scheduleError.startDay ? <FormHelperText>{scheduleError.msg}</FormHelperText> : null}
         </FormControl>
          {intervalValue === "Quarterly" ? (
          <FormControl sx={{ minWidth: 240,mr:2 }} size="small" error = {scheduleError.month}>
          <InputLabel id="no-of-select-month-label">Select Month</InputLabel>
          <Select
            labelId="no-of-select-month-label"
            id="demo-simple-select"
            value={month}
            label="Select Month"
            onChange={handleMonthChange}
            classes={{select:styles.selectInput}}
            sx={{height:43, borderRadius: 2.5}}
            MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
          >
            {monthArray.map((month:any) => {
              return (
                <MenuItem value={month}>{month}</MenuItem>
              )
            })}
          </Select>
          {scheduleError.month ? <FormHelperText>{scheduleError.msg}</FormHelperText> : null}
         </FormControl>
         ):null}
          <FormControl sx={{ minWidth: 240,mr:2 }} size="small" error = {scheduleError.scanNumber}>
          <InputLabel id="no-of-select-scan-number-label">Select No.of Scans</InputLabel>
          <Select
            labelId="no-of-select-scan-number-label"
            id="demo-simple-select"
            value={scanNumber}
            label="Select No.of Scans"
            onChange={scanNumberChangeHandler}
            classes={{select:styles.selectInput}}
            sx={{height:43, borderRadius: 2.5}}
            MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
          >
            {scanNumberArray.map((number:any) => {
              return (
                <MenuItem value={number}>{number}</MenuItem>
              )
            })}
          </Select>
          {scheduleError.scanNumber ? <FormHelperText>{scheduleError.msg}</FormHelperText> : null}
         </FormControl>
        </Grid>}
        {showRecurringFields && 
        <Grid item xs={12} >
          <Button
            id="review-btn"
            variant={"contained"}
            onClick={() => {
              reviewScanHandler()
            }}
            disabled = {(targetName === "" || (formValues.length === 1 && formValues[0].url === "")||intervalValue.length === 0 || scanNumber == "" || startDay === "" || (intervalValue === "Quarterly" && month === ""))}
            color="primary"
            data-testid="review-button"
            // classes={{root:styles.recurringBtn}}
          >
            Review Scan
          </Button>
        </Grid>}
        <Grid item xs={12} className={styles.ActionButtons}>
          <Button
            // To re-enable IP/Domain Verify change below function to handleSubmitDialogBox.
            onClick={submitAction}
            id="submit-btn"
            color="primary"
            variant={"contained"}
            data-testid="ok-button"
            type="submit"
            // disabled={submitDisabled}
          >
            {(showRecurringFields && scheduleCheckBox )? "Schedule Scan" : "Queue Scan"}
          </Button>
          <Button
            className={styles.borderLess}
            id="cancel-btn"
            variant={"contained"}
            onClick={handleBack}
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button>
        </Grid>
      </Grid>
      <DialogBox
        open={openDialogBox}
        handleOk={confirmSubmit}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.CreditMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {" "}
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          {priceData?.scannable === 1 ? (
            <p>By continuing you will consume 1 of your Credits.</p>
          ) : (
            <p>
              By continuing you will incur a charge of $
              {priceData?.scan_available?.price}.
            </p>
          )}
        </div>
      </DialogBox>
      <DialogBox
        open={openPricingBox}
        handleOk={handlePricingBoxClose}
        handleCancel={handlePricingBoxClose}
        buttonOk={"Ok"}
        skipCancel={true}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.PricingMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Pricing
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <div className="popup-body-wrap flex-container pricing-container">
            <div className="right-pricing-wrap">
              <div className="top-pricing-container">
                <div className="top-right-pricing-wrap detailed-tab">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Pricing per Scan</th>
                        <th style={{textAlign:"center"}}>Essential</th>
                        <th>Professional</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      <tr>
                        <td>Pen Test </td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - Internal</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - External</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogBox>
      <DialogBox
        open={showPopup}
        handleOk={() => {
          addLocalIpHandler()
        }}
        handleCancel = {
          cancelLocalIp
        }
        buttonOk={"Add"}
        buttonCancel={"Remove"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.CreditMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {" "}
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p><b style={{color:"#e53935"}}>WARNING:</b> When scanning local IP addresses, you may not receive any data. Please reconfirm the entered IP/Domain before proceeding.</p>
        </div>
      </DialogBox>
      <div>
    
    {/* {showPopup && (
      <div className={styles.DialogBoxContext}>
        <p>Entered Local IP Address</p>
      </div>
    )} */}
  </div>

    </React.Fragment>
  );
};

export default PenTest;
