import React, { useState, useEffect } from "react";
import styles from "./TaskDetails.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GET_SCAN_CONFIG } from "../../../graphql/queries/ScanConfig";
import { GET_SCANDATA } from "../../../graphql/queries/ScanData";
import { GET_TARGET } from "../../../graphql/queries/Target";
import { GET_TASK_DETAILS } from "../../../graphql/queries/TaskDetails";
import {
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
} from "../../../graphql/mutations/Task";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { customClient } from "../../../config/customClient";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox } from "../../../components/UI/Form/Checkbox/Checkbox";
import RaStepper from "../component/RaStepper/RaStepper";
import { useNavigate, useLocation } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import { setRaStepper } from "../common/SetRaStepper";
import { useApolloClient } from "@apollo/client";
import stepper from "../common/raStepperList.json";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import rerunstepper from "../common/raRerunStepperList.json";
import { setActiveFormStep } from "../../../services/Data";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import { ContactSupportOutlined, SelectAll } from "@mui/icons-material";
import {
  CREATE_TARGET,
  UPDATE_TARGET,
  DELETE_TARGET,
} from "../../../graphql/mutations/Target";
import * as msgConstant from "../../../common/MessageConstants";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import {
  PG_URL,
  PG_PRICING_API_KEY,
  PG_DEDUCTION_API_KEY,
} from "../../../config/index";
import QuestionMarkIcon from "@mui/icons-material/Help";
import * as Sentry from "@sentry/react";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const TaskDetails: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  const history = useNavigate();
  const location = useLocation();
  const [priceData, setPriceData] = useState<any>();
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [openPricingBox, setOpenPricingBox] = useState<boolean>(false);
  let scanArr: any = [];
  const pg360PartnerId = JSON.parse(
    localStorage.getItem("pg360PartnerId") || "{}"
  );
  const pg360UserId = JSON.parse(localStorage.getItem("pg360UserId") || "{}");
  const obUser = Cookies.getJSON("ob_user");

  const client = useApolloClient();
  const ReRunTargetName = JSON.parse(
    localStorage.getItem("re-runTargetName") || "{}"
  );
  // const localName = JSON.stringify(localStorage.getItem("name")) || "{}";
  const localName = localStorage.getItem("name")
    ? JSON.parse(localStorage.getItem("name") || "")
    : null;
  const [showbackdrop, setShowbackdrop] = useState(true);
  // const classes = useStyles(theme);
  const clientInfo = param.linuxDetails ? param.linuxDetails.clientInfo : param.windowsDetails ? param.windowsDetails.clientInfo : param.clientInfo;
  const targetInfo = param.linuxDetails ? param.linuxDetails.targetInfo  : param.windowsDetails ? param.windowsDetails.targetInfo : param.targetInfo;
  const targetName = JSON.parse(localStorage.getItem("name") || "{}");
  let scanConfigListItems = param ? param.scanConfigList : null;
  const scanListCheckBox: any = param ? param.scanConfigList : null;

  //Autocomplete list
  const [getScanDataList, setScanDataList] = useState([]);
  const [getTargetList, setTargetList] = useState([]);
  const [getScanConfigList, setScanConfigList] = useState<any>([]);

  // Show form
  const [showForm, setShowForm] = useState<boolean>(false);
  const [selectAllTask, setSelectAllTask] = useState<boolean>(false);
  const [params, setParams] = useState<any>({});
  //add/edit data

  const [name, setName] = useState<String>("");
  const [target, setTarget] = useState<String>("");
  const [scanConfig, setScanConfig] = useState<any>([]);

  //static values for partner and client are given.
  const tempScheduleDate = new Date().toISOString();
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const partnerId = partner.partnerId.id;
  const clientId = clientInfo ? clientInfo.name : undefined;
  const TargetNameTimestamp = localStorage.getItem("TargetNameTimestamp")
    ? JSON.parse(localStorage.getItem("TargetNameTimestamp") || "")
    : null;
  const WinTargetName = localStorage.getItem("WinTargetName")
    ? JSON.parse(localStorage.getItem("WinTargetName") || "")
    : null;
  const LinuxTargetName = localStorage.getItem("LinuxTargetName")
    ? JSON.parse(localStorage.getItem("LinuxTargetName") || "")
    : null;
  const targetId = localStorage.getItem("targetId") ? JSON.parse(localStorage.getItem("targetId") || "") : null;
  const winTargetId = localStorage.getItem("winTargetId") ? JSON.parse(localStorage.getItem("winTargetId") || "") : null;
  const LinuxTargetId = localStorage.getItem("LinuxTargetId") ? JSON.parse(localStorage.getItem("LinuxTargetId") || "") : null;
  const [backdrop, setBackdrop] = useState(false);
  const [windowLoginCheckBox, setWindowLoginCheckBox] = useState(false);
  const [linuxLoginCheckBox, setLinuxLoginCheckBox] = useState(false);
  const [taskDetailsCertCheckBox, setTaskDetailsCertCheckBox] = useState();
  const [emailUpdates, setEmailUpdates] = React.useState({
    checkedB: false,
  });
  let linuxtarget = param.linuxDetails ? param.linuxDetails : undefined;
  let windowstarget = param.windowsDetails ? param.windowsDetails : undefined;
  let scanName = param?.windowsDetails?.scanName || param?.linuxDetails?.scanName || param?.scanName
  let targetIpRange = param?.windowsDetails?.targetIpRange || param?.linuxDetails?.targetIpRange || param?.targetIpRange
  let subnetMask = param?.windowsDetails?.subnetMask || param?.linuxDetails?.subnetMask || param?.subnetMask

  //table
  const columns = [
    { title: "Task Name", field: "taskName" },
    { title: "Target", field: "target" },
    { title: "Scan Configuration", field: "scanConfig" },
  ];
  //show password
  const title = "Listing of Task";

  //validation and error handelling
  const [isError, setIsError] = useState<any>({
    name: "",
    scanConfig: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  useEffect(() => {
    if (location?.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
  }, []);

  useEffect(() => {
    if (param) {
      setParams(param);
    }
  }, []);

  useEffect(() => {
    if (param) {
      setTaskDetailsCertCheckBox(param?.windowsDetails?.checkboxSelected || param?.linuxDetails?.checkboxSelected || param?.checkboxSelected);
      setWindowLoginCheckBox(param?.windowsDetails?.windowLoginSelected || param?.linuxDetails?.windowLoginSelected || param?.windowLoginSelected);
      setLinuxLoginCheckBox(param?.windowsDetails?.linuxLoginSelected || param?.linuxDetails?.linuxLoginSelected || param?.linuxLoginSelected);
    }
  }, [param]);
  

  const session = Cookies.getJSON("ob_session");

  const [deleteTarget] = useMutation(DELETE_TARGET);

  const { data: taskData, loading: taskLoading } = useQuery(GET_TASK_DETAILS, {
    variables: {
      targetName: ReRunTargetName ? ReRunTargetName : targetName,
      client_ClientName: clientInfo?.name,
    },
    onCompleted: (data: any) => {
      if (data.getTask.edges[0]) {
        setScanConfig(data.getTask.edges[0].node.vatScanConfigList);
      }
    },
    onError: (err:any) =>{
      sentry_error_catch(err,setBackdrop,setFormState)
    },
    fetchPolicy: "cache-and-network",
  });

  //queries
  const [createTask] = useMutation(CREATE_TASK);

  if (
    scanListCheckBox !== undefined &&
    scanListCheckBox !== null &&
    scanListCheckBox.length !== 0 &&
    scanConfig.length === 0
  ) {
    setScanConfig(scanListCheckBox);
  }

  const {
    data: dataScanConfig,
    error: errorScanConfig,
    loading: loadingScanConfig,
    refetch: refetchScanConfig,
  } = useQuery(GET_SCAN_CONFIG, {
    onCompleted: () => {
      setShowbackdrop(false);
    },
    onError: (err:any) => {
      setShowbackdrop(false);
      sentry_error_catch(err,setBackdrop,setFormState)
    },

    fetchPolicy: "cache-and-network",
  });

  const checkValidation = () => {
    if (
      // isError.name !== "" ||
      // isError.scanConfig !== "" ||
      // !name ||
      scanConfig.length === 0
    ) {
      return true;
    }
    return false;
  };

  if (targetName && target === "") {
    let substring = "_linux";
    let substring2 = "_windows";
    localName.substring(
      localName.lastIndexOf(substring2) + 1,
      localName.length
    );
    if (localName.includes(substring)) {
      setTarget(
        localName.replace(
          "_" +
            localName.substring(
              localName.lastIndexOf(substring) + 1,
              localName.length
            ),
          ""
        )
      );
    } else if (localName.includes(substring2)) {
      setTarget(
        localName.replace(
          "_" +
            localName.substring(
              localName.lastIndexOf(substring2) + 1,
              localName.length
            ),
          ""
        )
      );
    } else {
      setTarget(localName);
    }
  }

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);
  useEffect(() => {
    setSubmitDisabled(checkValidation);
  }, [name, scanConfig, submitDisabled]);

  useEffect(() => {
    getPricingData();

    try {
      if (ReRunTargetName.includes("_windows")) {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          stepper.ScanConfiguration.name,
          stepper.ScanConfiguration.value,
          data
        );
      } else {
        setRaStepper(
          client,
          stepper.ScanConfiguration.name,
          stepper.ScanConfiguration.value,
          param
        );
      }
    } catch(err:any) {
      let data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : false,
        windowsNetwork:
          param && param.windowsNetwork ? param.windowsNetwork : true,
        editData: true,
        clientInfo: param && param.clientInfo ? param.clientInfo : null,
        targetInfo: param && param.targetInfo ? param.targetInfo : null,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData : false,
        targetName: ReRunTargetName ? ReRunTargetName : targetName,
      };
      setRaStepper(
        client,
        stepper.ScanConfiguration.name,
        stepper.ScanConfiguration.value,
        data
      );
    }
  }, []);

  useEffect(() => {
    if (getScanConfigList.length === 0 && dataScanConfig) {
      let arr: any = [];
      dataScanConfig.getScanConfigurationdata.edges
        .filter(
          (name: any) =>
            !name.node.scanConfigName.includes("Full and fast") &&
            !name.node.scanConfigName.includes("External scan config") &&
            !name.node.scanConfigName.includes("CRIS Scan Config") &&
            !name.node.scanConfigName.includes("Excluded scan configuration")
        )
        .map((filteredName: any) => {
          arr.push(filteredName);
        });
      setScanConfigList(arr);
    }
  }, [dataScanConfig]);

  const getPricingData = async () => {
    let headerObj = {
      "api-key": PG_PRICING_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      // partner id should be dynamic
      await fetch(PG_URL + "ob360-scans/api/v1/check-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          type: "VA",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setPriceData(data);
        });
    } catch (err:any) {
      Sentry.captureException(err);
    }
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
  };

  const confirmSubmit = () => {
    setOpenDialogBox(true);
  };

  const handleSubmitDialogBox = () => {
    try{
    setOpenDialogBox(false);
    if (Cookies.getJSON("ob_session")) {
      setBackdrop(true);
      setSubmitDisabled(true);
      let input = {
        partner: partnerId,
        client: clientId,
        clientid: clientInfo ? parseInt(clientInfo.clientId) : '',
        taskName: "Task" + " " + TargetNameTimestamp,
        vatTarget: TargetNameTimestamp,
        vatScanConfig: scanConfig,
        scheduleDate: tempScheduleDate,
        mainTargetId: targetId ? targetId : "",
        linuxTargetId: LinuxTargetId ? LinuxTargetId : "",
        windowsTargetId: winTargetId ? winTargetId : ""
      };
      createTask({
        variables: {
          input,
        },
      })
        .then((userRes) => {
          if (userRes.data.createTask.status === "Success") {
            setBackdrop(false);
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: "",
            }));
            setShowForm(false);
            let formState2 = {
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: msgConstant.SCAN_SUCCESS_MSG,
            };
            let data = {};
            data = {
              refetchData: true,
              clientInfo: clientInfo,
              formState: formState2,
            };
            // need to check param
            handleDeductScan(targetId);
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          } else {
            setBackdrop(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.API_FAILED_MSG,
            }));
          }
        })
        .catch((err:any) => {
          setBackdrop(false);
          setSubmitDisabled(true);
          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          }else if(error.includes("get() returned more than one Client -- it returned 2!")){
            error = msgConstant.API_FAILED_MSG;
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          }else {
            sentry_error_catch(err,setBackdrop,setFormState)
          }
          
        });
    } else {
      logout();
    }}catch(error){
        showBoundary(error)
    }
  };

  const handleDeductScan = async (targetId: any) => {
    let headerObj = {
      "api-key": PG_DEDUCTION_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      // partner id should be dynamic
      await fetch(PG_URL + "ob-ra-scans/api/v1/deduct-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          scan_type: "VA",
          uid: pg360UserId,
          main_type: "OB Assessment",
          product_type : "OB",
          timestamp: new Date().getTime() / 1000,
          target_id: targetId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
        });
    } catch (err:any) {
      Sentry.captureException(err);
    }
  };

  const handleBack = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      // try {
      //   // Rerun Windows
      //   if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
      //     let data = {
      //       LinuxNetwork:
      //         param && param.LinuxNetwork ? param.LinuxNetwork : false,
      //       windowsNetwork:
      //         param && param.windowsNetwork ? param.windowsNetwork : true,
      //       editData: true,
      //       clientInfo: param && param.clientInfo ? param.clientInfo : null,
      //       targetInfo: param && param.targetInfo ? param.targetInfo : null,
      //       editLinuxData: param.editLinuxData ? param.editLinuxData : false,
      //       editWindowsData: param.editWindowsData
      //         ? param.editWindowsData
      //         : false,
      //       targetName: ReRunTargetName ? ReRunTargetName : targetName,
      //       previousPage: param?.previousPage,
      //       checkboxSelected: taskDetailsCertCheckBox,
      //       windowLoginSelected: windowLoginCheckBox,
      //       linuxLoginSelected: linuxLoginCheckBox,
      //     };

      //     if (param && param.LinuxNetwork) {
      //       setRaStepper(
      //         client,
      //         rerunstepper.LinuxNetwork.name,
      //         rerunstepper.LinuxNetwork.value,
      //         data
      //       );
      //       history(routeConstant.LINUX_NETWORK, { state: { data } });
      //     } else if (param && !param.LinuxNetwork && WinTargetName) {
      //       setRaStepper(
      //         client,
      //         rerunstepper.WindowsNetwork.name,
      //         rerunstepper.WindowsNetwork.value,
      //         data
      //       );
      //       history(routeConstant.WINDOWS_NETWORK, { state: { data } });
      //     } else {
      //       setRaStepper(
      //         client,
      //         rerunstepper.Target.name,
      //         rerunstepper.Target.value,
      //         data
      //       );
      //       history(routeConstant.TARGET, { state: { data } });
      //     }
      //   }
      //   // Rerun Linux
      //   else {
      //     let data = {
      //       LinuxNetwork:
      //         param && param.LinuxNetwork ? param.LinuxNetwork : false,
      //       windowsNetwork:
      //         param && param.windowsNetwork ? param.windowsNetwork : true,
      //       editData: true,
      //       clientInfo: param && param.clientInfo ? param.clientInfo : null,
      //       targetInfo: param && param.targetInfo ? param.targetInfo : null,
      //       editLinuxData: param.editLinuxData ? param.editLinuxData : false,
      //       editWindowsData: param.editWindowsData
      //         ? param.editWindowsData
      //         : false,
      //       previousPage: param?.previousPage,
      //       checkboxSelected: taskDetailsCertCheckBox,
      //       windowLoginSelected: windowLoginCheckBox,
      //       linuxLoginSelected: linuxLoginCheckBox,
      //     };
      //     if (WinTargetName) {
      //       history(routeConstant.WINDOWS_NETWORK, { state: { data } });
      //     } else if (param && param.LinuxNetwork) {
      //       history(routeConstant.LINUX_NETWORK, { state: { data } });
      //     } else {
      //       history(routeConstant.TARGET, { state: { data } });
      //     }
      //   }
      // } catch {
      //   // Normal
      //   let data = {
      //     LinuxNetwork:
      //       param && param.LinuxNetwork ? param.LinuxNetwork : false,
      //     windowsNetwork:
      //       param && param.windowsNetwork ? param.windowsNetwork : true,
      //     editData: true,
      //     clientInfo: param && param.clientInfo ? param.clientInfo : null,
      //     targetInfo: param && param.targetInfo ? param.targetInfo : null,
      //     editLinuxData: param.editLinuxData ? param.editLinuxData : false,
      //     editWindowsData: param.editWindowsData
      //       ? param.editWindowsData
      //       : false,
      //     previousPage: param?.previousPage,
      //     checkboxSelected: taskDetailsCertCheckBox,
      //     windowLoginSelected: windowLoginCheckBox,
      //     linuxLoginSelected: linuxLoginCheckBox,
      //   };
      //   if (WinTargetName) {
      //     history(routeConstant.WINDOWS_NETWORK, { state: { data } });
      //   } else if (param && param.editLinuxData) {
      //     history(routeConstant.LINUX_NETWORK, { state: { data } });
      //   } else {
      //     history(routeConstant.TARGET, { state: { data } });
      //   }
      // }
      if(param?.linuxDetails?.linuxTargetDetails?.linuxTestConnection || param?.windowsDetails?.windowsTargetDetails?.windowsTestConnection || targetId){
        let data;
        if(param?.linuxDetails?.linuxTargetDetails?.linuxTestConnection || param?.windowsDetails?.windowsTargetDetails?.windowsTestConnection ){
        data = {
          LinuxNetwork:
            linuxtarget?.LinuxNetwork ? linuxtarget?.LinuxNetwork : false,
          windowsNetwork:
            windowstarget?.windowsNetwork ? windowstarget?.windowsNetwork : true,
          editData: true,
          clientInfo: clientInfo,
          targetInfo: targetInfo,
          editLinuxData: linuxtarget?.editLinuxData ? linuxtarget?.editLinuxData : false,
          editWindowsData: windowstarget?.editWindowsData
            ? windowstarget?.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: taskDetailsCertCheckBox,
          windowLoginSelected: windowLoginCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          scanName : scanName ?  scanName : '',
          targetIpRange : targetIpRange ? targetIpRange : '',
          subnetMask : subnetMask ? subnetMask : '',
        };
       }else{
        data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: true,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: taskDetailsCertCheckBox,
          windowLoginSelected: windowLoginCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          scanName : scanName ?  scanName : '',
          targetIpRange : targetIpRange ? targetIpRange : '',
          subnetMask : subnetMask ? subnetMask : '',
        };
       }
        history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
      }else{
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: true,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: taskDetailsCertCheckBox,
          windowLoginSelected: windowLoginCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          scanName : scanName ?  scanName : '',
          targetIpRange : targetIpRange ? targetIpRange : '',
          subnetMask : subnetMask ? subnetMask : '',
        };
        history(routeConstant.VPN_CONNECT, { state: { data } });
      }
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    let value = event.target.value;
    let isErrName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      name: isErrName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleCheckElement = (event: any) => {
    let val = event.target.value;
    scanArr = [...scanConfig];
    if (scanArr.includes(val)) {
      let position = scanArr.indexOf(val);
      if (position >= 0) {
        scanArr.splice(position, 1);
      }
    } else {
      scanArr.push(val);
    }
    if (scanArr.length === 11) {
      setEmailUpdates({ ...emailUpdates, ["checkedB"]: true });
    }
    if (scanArr.length !== 11) {
      setEmailUpdates({ ...emailUpdates, ["checkedB"]: false });
    }
    setScanConfig(scanArr);
    let isErrScanArr = scanArr.length === 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      scanConfig: isErrScanArr,
    }));
    setSubmitDisabled(checkValidation);
  };
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try{
    if (event.target.checked === true) {
      let arr: any = [];
      dataScanConfig.getScanConfigurationdata.edges
        .filter(
          (name: any) =>
            !name.node.scanConfigName.includes("Full and fast") &&
            !name.node.scanConfigName.includes("External scan config") &&
            !name.node.scanConfigName.includes("CRIS Scan Config") &&
            !name.node.scanConfigName.includes("Excluded scan configuration")
        )
        .map((filteredName: any) => {
          arr.push(filteredName.node.vatScanConfigId);
        });
      setScanConfig(arr);
    }
    if (event.target.checked === false) {
      let arr: any = [];
      setScanConfig(arr);
    }
    setEmailUpdates({
      ...emailUpdates,
      [event.target.name]: event.target.checked,
    });
    }catch(error){
    showBoundary(error)
  }
  };
  let obuserData = JSON.parse(obUser);
  let firstName = obuserData.data.getUserDetails.edges[0].node.firstName;
  let lastName = obuserData.data.getUserDetails.edges[0].node.lastName;
  const handleCancel = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      deleteTarget({
        variables: {
          id: Number(targetId),
          lastName,
          firstName,
        },
      })
        .then((res: any) => {
          let data = {};

          data = { refetchData: true, clientInfo: clientInfo };
          if (params.previousPage === "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setBackdrop,setFormState)
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (params.previousPage === "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          }
        });
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };
  const handlePricingBoxOpen = () => {
    setOpenPricingBox(true);
  };
  const handlePricingBoxClose = () => {
    setOpenPricingBox(false);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param.clientInfo !== undefined
          ? param.clientInfo.name
          : null}
        {priceData?.scannable === 1 ? (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Credits:{" "}
            {priceData.scan_available.free + priceData.scan_available.credit}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <IconButton
          aria-label="close"
          id="pricing-btn"
          color="inherit"
          size="small"
          onClick={handlePricingBoxOpen}
        >
          <QuestionMarkIcon fontSize="inherit" />
        </IconButton> */}
      </Typography>
      <RaStepper />
      <Grid container spacing={3}>
        {showbackdrop || backdrop ? <SimpleBackdrop /> : null}
        <Grid item xs={12}>
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12} className={styles.mainCheck}>
          <label className={styles.HeaderLabel}>
            Select Scan Configuration
          </label>
          <Grid container spacing={1}>
            <Grid item xs={6} md={12}>
              <div className={styles.cbox}>
                <FormControlLabel
                  className={styles.CheckboxLabel}
                  control={
                    <Checkbox
                      checked={emailUpdates.checkedB}
                      onChange={handleCheckBoxChange}
                      name="checkedB"
                      value=""
                    />
                  }
                  label="Select All"
                />
              </div>
            </Grid>
            <Grid item xs={12} className={styles.ConfigItem}>
              {getScanConfigList.map((obj: any, i: any) => {
                return (
                  <FormControlLabel
                    className={styles.CheckboxLabel}
                    key={obj.node ? obj.node.vatScanConfigId : null}
                    control={
                      <Checkbox
                        id={obj.node && obj.node.scanConfigName}
                        checked={
                          scanConfig.includes(
                            obj.node ? obj.node.vatScanConfigId : null
                          ) || emailUpdates.checkedB
                        }
                        onChange={handleCheckElement}
                        name={obj.node ? obj.node.scanConfigName : null}
                        value={obj.node ? obj.node.vatScanConfigId : null}
                      />
                    }
                    label={obj.node ? obj.node.scanConfigName : null}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.backToListButton}>
          <Button
            variant={"contained"}
            onClick={handleBack}
            id="back-btn"
            color="primary"
            data-testid="cancel-button"
            className={styles.borderLess}
          >
            back
          </Button>
          <Button
            onClick={confirmSubmit}
            color="primary"
            id="submit-btn"
            variant={"contained"}
            data-testid="ok-button"
            disabled={submitDisabled}
          >
            Queue Scan
          </Button>
          <Button
            className={styles.borderLess}
            variant={"contained"}
            id="cancel-btn"
            onClick={handleCancel}
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button>
        </Grid>
      </Grid>
      <DialogBox
        open={openDialogBox}
        handleOk={handleSubmitDialogBox}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.CreditMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {" "}
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          {priceData?.scannable === 1 ? (
            <p>By continuing you will consume 1 of your Credits.</p>
          ) : (
            <p>
              By continuing you will incur a charge of $
              {priceData?.scan_available?.price}.
            </p>
          )}
        </div>
      </DialogBox>
      <DialogBox
        open={openPricingBox}
        handleOk={handlePricingBoxClose}
        handleCancel={handlePricingBoxClose}
        buttonOk={"Ok"}
        skipCancel={true}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.PricingMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Pricing
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <div className="popup-body-wrap flex-container pricing-container">
            <div className="right-pricing-wrap">
              <div className="top-pricing-container">
                <div className="top-right-pricing-wrap detailed-tab">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Pricing per Scan</th>
                        <th>Essential</th>
                        <th>Professional</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      <tr>
                        <td>Pen Test </td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - Internal</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - External</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogBox>
    </React.Fragment>
  );
};

export default TaskDetails;
