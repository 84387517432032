import React, { useEffect,useState } from "react";
import styles from "./Header.module.css";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { useErrorBoundary } from 'react-error-boundary';

export const Header: React.FC = () => {
  const history = useNavigate();
  const { resetBoundary, showBoundary } = useErrorBoundary();
  const [userNameState,setUserNameState] = useState("")
  // const user =  JSON.parse(localStorage.getItem("user") || "{}");
  const userdata = Cookies.getJSON("ob_user") || "{}";
  const user = typeof userdata === "object" ? userdata : JSON.parse(userdata);
  // const user = Cookies.getJSON("ob_user")
  //   ? Cookies.getJSON("ob_user") || ""
  //   : Logout();
  const ob_partnerData = Cookies.getJSON("ob_partnerData")
    ? Cookies.getJSON("ob_partnerData") || ""
    : null;
  let userRole: any;
  let username: any;

  const getHelpManual = () => {
    resetBoundary()
    // let ra_manual = process.env.PUBLIC_URL + "/user_manual/OB360_Manual.pdf";
    let ra_manual = process.env.REACT_APP_HELP_URL;
    window.open(ra_manual, "_blank");
  };
  const getSettings = () => {
    resetBoundary()
    history(routeConstant.SETTINGS);
    // let ra_manual = process.env.PUBLIC_URL + "/user_manual/OB360_Manual.pdf"
    // window.open(ra_manual, '_blank');
  };
  const getHome = () => {
    resetBoundary()
    try{
    if (user?.data?.getUserDetails?.edges[0]?.node?.isSuperuser) {
      history(routeConstant.ADMIN_DASHBOARD);
    } else {
      history(routeConstant.CLIENT);
      // localStorage.clear();
      localStorage.removeItem("name");
      localStorage.removeItem("customClientUrl");
      localStorage.removeItem("targetId");
      localStorage.removeItem("ipRange");
      localStorage.removeItem("ipAddress");
      localStorage.removeItem("re-runTargetName");
      localStorage.removeItem("userName");
      localStorage.removeItem("password");
      localStorage.removeItem("vpnUserName");
      localStorage.removeItem("vpnPassword");
      localStorage.removeItem("vpnFilePath");
      localStorage.removeItem("WinTargetName");
      localStorage.removeItem("LinuxTargetName");
      localStorage.removeItem("subnetMask");
      localStorage.removeItem("LinuxTargetId");
      localStorage.removeItem("winTargetId");
      localStorage.removeItem("updatetargettimestamp");
      localStorage.removeItem("WinIpAddress");
      localStorage.removeItem("TargetNameTimestamp");
      localStorage.removeItem("winUsername");
      localStorage.removeItem("winPassword");
      localStorage.removeItem("initialSubnetMask");
      localStorage.removeItem("initialTargetIpRange");
    }}catch(error){
      showBoundary(error)
    }
  };
  const getScanDetails = () => {
    resetBoundary()
    history(routeConstant.SCAN_DETAILS);
  }
  
  useEffect(() => {
    try {
      username = user.data.getUserDetails.edges[0].node.firstName + " " + user.data.getUserDetails.edges[0].node.lastName;
      setUserNameState(username)
    } catch (err:any) {
      if (user?.data?.getUserDetails?.edges[0]?.node?.username !== undefined) {
        username = user?.data?.getUserDetails?.edges[0]?.node?.username;
        setUserNameState(username)
      }else{
        Sentry.captureException(err)
        Logout();
      }
    }
  },[])
  
  return (
    <div className={styles.Header} data-testid="Header">
      <Card className={styles.root}>
        <Grid container>
          <Grid item xs={12} className={styles.RALogoImg}>
            <div>
              <img
                id="get-home"
                src={process.env.PUBLIC_URL + "/OB360.png"}
                alt="user icon"
                onClick={getHome}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={styles.userInfo}>
            <>
              <span className={styles.userNameLabel}>
                {userNameState}
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              {ob_partnerData ? (
                <span className={styles.userNameLabel}>
                  <a
                    id="scan-details"
                    className={styles.logout}
                    onClick={getScanDetails}
                  >
                    Usage
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
              ) : null}
              {ob_partnerData ? (
                <span className={styles.userNameLabel}>
                  <a
                    id="settings"
                    className={styles.logout}
                    onClick={getSettings}
                  >
                    Settings
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
              ) : null}
              <span className={styles.userNameLabel}>
                <a id="help" className={styles.logout} onClick={getHelpManual}>
                  Help
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              <span className={styles.userNameLabel}>
                <a id="logout" className={styles.logout} onClick={Logout}>
                  Logout
                </a>
              </span>
            </>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Header;
